import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    delta: Number
  }

  connect() {
    const calcHeight = () => {
      this.element.style.setProperty('--height', (this.element.clientHeight + (this.deltaValue ?? 0)) + 'px')
    }
    new ResizeObserver(calcHeight).observe(this.element)
    calcHeight()
  }
}