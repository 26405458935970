import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { mdWindow: Number }

  findCurrentPageIndex(pages) {
    let currentPageIndex = 0
    for (let i = 0; i < pages.length; i++) {
      if (pages[i].classList.contains('current')) {
        currentPageIndex = i
        break
      }
    }
    return currentPageIndex
  }

  hidePagesRight(pages, currentPageIndex) {
    for (let radius = this.mdWindowValue + 1; radius < pages.length - currentPageIndex; radius++) {
      pages[currentPageIndex + radius].classList.add('md-hidden')
    }
  }

  hidePagesLeft(pages, currentPageIndex) {
    for (let radius = this.mdWindowValue + 1; radius <= currentPageIndex; radius++) {
      pages[currentPageIndex - radius].classList.add('md-hidden')
    }
  }

  connect() {
    const pages = this.element.querySelectorAll('.page:not(.gap)')
    if (pages.length > this.mdWindowValue * 2 + 1) {
      const currentPageIndex = this.findCurrentPageIndex(pages)
      this.hidePagesLeft(pages, currentPageIndex)
      this.hidePagesRight(pages, currentPageIndex)
    }
  }
}