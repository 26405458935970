import { Controller } from "@hotwired/stimulus"
import { countries } from "./dialcode_data"
import { countryCodeEmoji } from "country-code-emoji"
import $ from "jquery"

export default class extends Controller {
  static targets = ['select', 'phoneNumber']

  connect() {
    const { countryCode, localNumber, country } = this.selectTarget.dataset
    console.log({ countryCode, localNumber, country })

    const dialCodeSelect = $(this.selectTarget)
    dialCodeSelect.select2({
      selectionCssClass: 'dialcode-selector',
      templateSelection: (data) => countryCodeEmoji(data.id),
      data: countries.map(country => ({
        id: country.iso2,
        country,
        text: `${countryCodeEmoji(country.iso2)} ${country.name.replace(/\s*\(.*\)$/, '')} (+${country.dialCode})`,
      })),
      width: '100%'
    })
    .val(country.toLowerCase()).trigger('change')
    .on('select2:select', event => {
      const { data: { country } } = event.params
      this.phoneNumberTarget.dataset.countryCode = country.dialCode
    })
    this.phoneNumberTarget.dataset.countryCode = countryCode
    this.updatePhoneNumberInput(this.phoneNumberTarget.querySelector('input'), localNumber)
  }

  updatePhoneNumberInput(input, localNumber) {
    const hidden = document.createElement('input')
    hidden.type = 'hidden'
    hidden.value = input.value
    hidden.name = input.name
    input.value = localNumber
    input.parentNode.appendChild(hidden)
    
    input.form.addEventListener('submit', (e) => {
      e.preventDefault()
      hidden.value = `+${this.phoneNumberTarget.dataset.countryCode}${input.value.replace(/\D/g, '')}`
      input.disabled = true
      input.form.submit()
      input.disabled = false
    })
  }

  disconnect() {
    $(this.selectTarget).select2('destroy');
  }
}