import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  alert(message, icon, autoDismiss) {
    const response = fetch('/alert', {
      method: "POST",
      mode: "cors",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        icon: icon,
        message: message,
        auto_dismiss: autoDismiss
      }),
    }).then (response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }
}
