import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static values = { time: Boolean, noDate: Boolean, mode: String, format: String };
  static targets = [
    'startDatetime', 'endDatetime', 'control'
  ]

  connect() {
    let stimulus = this

    console.log('loading flatpickr')

    this.flatpickr = flatpickr(this.targetElement(), {
      enableTime: stimulus.enableTime(),
      noCalendar: stimulus.noCalendar(),
      mode: stimulus.mode(),
      dateFormat: stimulus.dateFormat(),
      onChange: function(selectedDates) {
        stimulus.setValues(selectedDates)
        stimulus.dispatchDateRangeEvent(selectedDates)
      }
    })
  }

  targetElement() {
    return this.hasControlTarget ? this.controlTarget : this.element
  }

  disconnect() {
    this.flatpickr.destroy()
  }

  enableTime() {
    return this.timeValue || false
  }

  noCalendar() {
    return this.noDateValue || false
  }

  mode() {
    return this.modeValue || 'single'
  }

  dateFormat() {
    return this.formatValue || 'm/d/Y'
  }

  setValues(selectedDates) {
    if (this.modeValue == 'range') {
      this.setStartAndEndDates(selectedDates)
    } else {
      this.setSingleDate(selectedDates)
    }
  }

  setStartAndEndDates(selectedDates) {
    if (selectedDates.length === 2) {
      this.startDatetimeTarget.value = selectedDates[0].toDateString()
      this.endDatetimeTarget.value = selectedDates[1].toDateString()
    } else if (selectedDates.length === 0) {
      this.startDatetimeTarget.value = null
      this.endDatetimeTarget.value = null
    }
  }

  setSingleDate(selectedDates) {
    if (selectedDates.length === 1) {
      this.startDatetimeTarget.value = selectedDates[0].toTimeString().split(' ')[0]
    } else if (selectedDates.length === 0) {
      this.startDatetimeTarget.value = null
    }
  }

  dispatchDateRangeEvent(selectedDates) {
    if (this.modeValue == 'range' && (selectedDates.length === 2 || selectedDates.length === 0)) {
      this.targetElement().dispatchEvent(new CustomEvent('date-range-selected'))
    }
  }
}
