import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['value', 'minus', 'plus']

  static values = { min: Number, max: Number }

  quantity;

  value;

  setNewItemQuantity(e) {
    const returnableQuantity = e.selectedOptions[0].getAttribute('data-returnable-quantity')
    this.value = +returnableQuantity
    this.maxValue = +returnableQuantity
    this.sync()
    this.updateDisplay()
  }

  newOrderChange() {
    const orderSelector = document.querySelector(`.order-item-${this.data.get("id")}`)
    this.setNewItemQuantity(orderSelector)
    orderSelector.addEventListener('change', () => this.setNewItemQuantity(orderSelector))
  }

  connect() {
    this.quantity = this.element.querySelector('[data-quantity]')
    this.value = +this.quantity.dataset.quantity
    if (this.data.get("new") == "true") {
      this.newOrderChange()
    } else {
      this.updateDisplay()
    }
  }

  updateDisplay() {
    this.valueTargets.forEach((element) => {
      element.innerHTML = this.value
    })
    this.minusTarget.classList.toggle('opacity-10', this.value <= this.minValue || this.maxValue == 0)
    this.plusTarget.classList.toggle('opacity-10', this.value >= this.maxValue)
  }

  sync() {
    this.quantity.value = this.value
    this.quantity.dispatchEvent(new Event('change'))
  }

  adjustValue(event, increment) {
    event.preventDefault();
    const canDecrease = increment === -1 && (this.value > this.minValue && this.maxValue > 0);
    const canIncrease = increment === 1 && (this.value < this.maxValue);

    if (canDecrease || canIncrease) {
      this.value += increment;
      this.updateDisplay();
      this.sync();
    }
  }
  minus(event) {
    this.adjustValue(event, -1);
  }
  plus(event) {
    this.adjustValue(event, 1);
  }
}