import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    publishableKey: String,
    nextPath: String,
    paymentMethodTypes: Array,
    returnUrl: String
  }
  static targets = [
    'form',
    'formError',
    'submitButton',
    'routingNumber',
    'accountNumber',
    'accountHolderName',
    'accountHolderType',
    'cardInput'
  ]

  disconnect() {
    this.formTarget.removeEventListener('submit', this.bindedCallbackHandler);
    this.cardElement?.destroy;
  }

  connect() {
    let stimulus = this
    this.stripe = Stripe(this.publishableKeyValue)

    this.disableSubmitButton()

    this.elements = this.stripe.elements({
      mode: 'setup',
      currency: 'usd',
      setupFutureUsage: 'off_session',
      paymentMethodTypes: this.paymentMethodTypesValue,
      appearance: {

      }
    })
    this.cardElement = this.elements.create('payment')
    this.cardElement.on('change', function(event) {
      if (event.complete) {
        // enable payment button
        stimulus.enableSubmitButton()
      } else if (event.error) {
        // show validation to customer
        stimulus.enableSubmitButton()
      }
    })
    this.cardElement.mount(this.cardInputTarget)

    this.bindedCallbackHandler = this.callbackHandler.bind(this);
    this.formTarget.addEventListener('submit', this.bindedCallbackHandler);
  }

  async callbackHandler(e) {
    e.preventDefault()
    this.disableSubmitButton()
    this.clearExistingErrors()

    window.dispatchEvent(new CustomEvent('start-loading'))

    const { error: submitError } = await this.elements.submit();
    if (submitError) {
      this.displayError(submitError);
      return;
    }

    const { client_secret: clientSecret } = await this.getSetupIntent();
    const { error } = await this.confirmSetupIntent(clientSecret);

    if (error) {
      this.displayError(error);
    }
  }

  async confirmSetupIntent(clientSecret) {
    return this.stripe.confirmSetup({
      elements: this.elements,
      clientSecret,
      confirmParams: {
        return_url: this.returnUrlValue
      },
    });
  }

  async getSetupIntent() {
    let csrfToken = document.getElementsByName("csrf-token")[0].content
    return fetch('/stripe/setup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    }).then((res) => res.json());
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true
  }

  enableSubmitButton() {
    this.submitButtonTarget.removeAttribute("disabled")
  }

  displayError(error) {
    window.dispatchEvent(new CustomEvent('stop-loading'))
    const match = /\[(.*?)\]$/.exec(error.param)
    let feedback = this.createFeedbackElement(error.message)
    if (match && match[1]) {
      const camelCased = match[1].replace(/_(\w)/g, (word, index) => word[1].toUpperCase())
      const target = this[camelCased + 'Target']
      if (target) {
        target.parentNode.classList.add('is-invalid')
        target.parentNode.insertAdjacentElement('afterend', feedback)
      }
    } else {
      this.formTarget.insertAdjacentElement('afterbegin', feedback)
    }
    this.enableSubmitButton()
  }

  clearExistingErrors() {
    document.querySelectorAll('.is-invalid').forEach(element => {
      element.classList.remove('is-invalid')
    })
    document.querySelectorAll('.invalid-feedback').forEach(element => {
      element.remove()
    })
  }

  createFeedbackElement(message) {
    let feedback = document.createElement('div')
    feedback.classList.add('invalid-feedback')
    feedback.innerHTML = message

    return feedback
  }
}
