import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String, method: String }

  request(event) {
    let csrfToken = document.getElementsByName("csrf-token")[0].content
    if (!this.urlValue) return
    console.log(this.urlValue)
    fetch(this.urlValue, {
      method: this.methodValue,
      mode: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    }).then(response => response.json()).then(data => {
      console.log(data)
    }).catch((error) => {
    })
  }
}
