import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['inputField', 'targetField']
  static values = {
    content: String
  }

  append() {
    this.targetFieldTarget.value = this.inputFieldTarget.value + this.contentValue
  }
}