import Split from 'split.js'

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    Split(this.element.children, {
      minSize: 410
    })
  }
}
