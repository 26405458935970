export const useDatasetFilters = controller => {
  Object.assign(controller, {

    addFilterToDataset(element, key){
      const currentFilters = this.getFilters(element)
      currentFilters[key]  = true

      this.setFilters(element, currentFilters)
    },

    removeFilterFromDataset(element, key){
      const currentFilters = this.getFilters(element)
      delete(currentFilters[key])

      this.setFilters(element, currentFilters)
    },

    getFilters(element){
      return element.dataset.filters ? JSON.parse(element.dataset.filters) : {}
    },

    setFilters(element, filters){
      if( Object.keys(filters).length === 0 ){
        delete(element.dataset.filters)
      }else{
        element.dataset.filters = JSON.stringify(filters)
      }
    }
  })
}
