import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'form'];
  static values = {
    successMessage: String,
    failureMessage: String
  }

  post(e) {
    e.preventDefault();
    e.stopPropagation();

    let form = this.formTarget;
    let button = this.buttonTarget;
    let successMessage = this.successMessageValue;
    let failureMessage = this.failureMessageValue;

    const response = fetch(button.getAttribute('formaction'), {
      method: "POST",
      mode: "cors",
      body: new FormData(form),
    }).then((data) => {
      if (data.ok) {
        this.alert(successMessage, 'check', true)
      } else {
        this.alert(failureMessage, 'exclamation', false)
      }
    });
  }

  alert(message, icon, autoDismiss) {
    let body = document.body
    const alertController = this.application.getControllerForElementAndIdentifier(body, 'alert')
    alertController.alert(message, icon, autoDismiss)
  }
}
