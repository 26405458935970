import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

class Upload {
  onprogress;
  file;
  constructor(file, url, callback, onprogress) {
    this.onprogress = onprogress
    this.file = file
    const upload = new DirectUpload(file, url, this)
    console.log(`uploading ${file.name}`, url)
    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.log(error)
        alert('Something went wrong...')
      } else {
        callback(blob.signed_id)
        this.onprogress(100)
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
    request.upload.addEventListener("loadend",
      event => this.onprogress(99))
  }

  directUploadDidProgress(event) {
    const percent = event.loaded * 100 / event.total
    console.log(`uploading ${this.file.name} ${percent}%`)
    this.onprogress(Math.min(99, percent))
  }
}

export default class extends Controller {
  static targets = ['fileField', 'preview']

  upload(file) {
    if (!file) return
    if (!this.matchMimeType(file.type)) return
    this.previewTarget.src = URL.createObjectURL(file)
    const url = this.fileFieldTarget.dataset.directUploadUrl
    new Upload(file, url, (signed_id) => {
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute("type", "hidden")
      hiddenField.name = this.fileFieldTarget.name
      hiddenField.dataset.errorKey = this.fileFieldTarget.dataset.errorKey
      this.element.appendChild(hiddenField)
      console.log(hiddenField)
      hiddenField.value = signed_id
      hiddenField.form?.dispatchEvent(new CustomEvent("change"))
    }, progress => {
      console.log({ progress })
    })
  }

  _matchMimeType(type, mime) {
    if (mime.endsWith('/*')) {
      return type.startsWith(mime.substring(0, mime.indexOf('/')))
    } else {
      return mime === type
    }
  }

  matchMimeType(type) {
    const accept = this.fileFieldTarget.accept
    if (!accept) return true
    let match = false
    for (const mime of accept.split(/,\s*/)) {
      match = this._matchMimeType(type, mime)
      if (match) break
    }
    return match
  }

  setupDropEvent(element) {
    element.addEventListener('dragover', (event) => {
      event.preventDefault()

      for (const item of event.dataTransfer.items) {
        const match = this.matchMimeType(item.type)
        event.dataTransfer.dropEffect = match ? "copy" : "none"
      }
    })

    element.addEventListener('drop', (event) => {
      event.preventDefault()
      const [file] = event.dataTransfer.files
      console.log(file.type)
      this.upload(file)
    })
  }

  connect() {
    this.setupDropEvent(this.element)
    this.fileFieldTarget.addEventListener('change', () => {
      const [file] = this.fileFieldTarget.files
      this.upload(file)
    })
  }
}