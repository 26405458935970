import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  initialize(){
    this.submit = debounce(this.submit, 300).bind(this)
  }

  submit() {
    Turbo.navigator.submitForm(this.element)
  }
}
