import { Controller } from "@hotwired/stimulus"
import { useDatasetFilters } from "./mixins/use_dataset_filters"

export default class extends Controller {
  static outlets = ['table-filter-render']
  static values  = {
    comparisonType: String,
    comparisonSelector: String,
    comparisonFindPath: String,
  }

  identifier = crypto.randomUUID()

  connect() {
    useDatasetFilters(this)
  }

  filter() {
    document
      .querySelectorAll(this.comparisonSelectorValue)
      .forEach(this.compare.bind(this))

    this.tableFilterRenderOutlet.render()
  }

  // Calls appropriate comparison function based on comparisonTypeValue
  // and adds or removes filter from dataset
  compare(element) {
    const filterValue  = this.element.value
    const elementValue = this.elementValue(element, this.comparisonFindPathValue)

    if (this[this.comparisonTypeValue](filterValue, elementValue)) {
      this.addFilterToDataset(element.closest('tr'), this.identifier)
    } else {
      this.removeFilterFromDataset(element.closest('tr'), this.identifier)
    }
  }

  contains(filterValue, elementValue) {
    const searchString = elementValue.replace(/\r?\n|\r/gm, '').trim().toUpperCase()
    const filterString = filterValue.toUpperCase()

    return filterString && !searchString.includes(filterString)
  }

  equals(filterValue, elementValue) {
    return filterValue && elementValue.toString() != filterValue.toString()
  }

  elementValue(element, path) {
    if (!this.allowedFindPath(path)) { return '' }

    let paths   = path.split('.')
    let current = element

    for (let i = 0; i < paths.length; ++i) {
      if (current[paths[i]] == undefined) {
        return undefined;
      } else {
        current = current[paths[i]];
      }
    }

    return current;
  }

  allowedFindPath(path) {
    const safeList = [ /dataset.*/, /checked/, /value/, /textContent/ ]

    return safeList.some(rx => rx.test(path))
  }
}
