import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview", 'edit']

  toggleElement(element, isHide) {
    if(isHide) {
      element.classList.add('hidden')
    } else {
      element.classList.remove('hidden')
    }
  }

  edit(e) {
    e.preventDefault();
    const toEdit = e.target.dataset.editQuantityTarget !== 'edit'
    this.previewTargets.forEach(element => this.toggleElement(element, toEdit))
    this.editTargets.forEach(element => this.toggleElement(element, !toEdit))
  }

}
