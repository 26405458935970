import { Controller } from "@hotwired/stimulus"

/*
 * A Stimulus controller that can be used to add progressive enhancements
 * to table row elements
 */
export default class extends Controller {
  static values =  {
    url: String
  }

  navigate(e){
    Turbo.visit(this.urlValue)
  }
}
