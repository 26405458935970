import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'iframe',
    'form',
    'button'
  ]

  originalAction
  originalTarget

  connect() {
    this.originalAction = this.formTarget.getAttribute('action')
    this.originalTarget = this.formTarget.getAttribute('target')
    this.formTarget.addEventListener('change', () => {
      this.loadiFrame()
    })
    this.loadiFrame()
  }

  preview(e) {
    e.preventDefault()
    e.stopPropagation()

    this.openInNewTab()
  }

  openInNewTab() {
    this.submit(this.newAction(), this.newTarget(), this.newOnSubmit())
  }

  loadiFrame() {
    this.submit(this.newAutoAction(), this.newAutoTarget())
  }

  submit(action, target, onsubmit) {
    this.formTarget.setAttribute('action', action)
    this.formTarget.setAttribute('target', target)
    if (onsubmit) {
      this.formTarget.setAttribute('onsubmit', onsubmit)
    } else {
      this.formTarget.removeAttribute('onsubmit')
    }
    this.formTarget.submit()
    this.formTarget.setAttribute('action', this.originalAction)
    this.formTarget.setAttribute('target', this.originalTarget)
  }

  newAutoTarget() {
    return this.buttonTarget.getAttribute('formautotarget')
  }

  newTarget() {
    return this.buttonTarget.getAttribute('formtarget')
  }

  newOnSubmit() {
    return `window.open('about:blank','${this.newTarget()}','width=1000,height=800')"`
  }

  newAutoAction() {
    return this.buttonTarget.getAttribute('formautoaction')
  }

  newAction() {
    return this.buttonTarget.getAttribute('formaction')
  }
}