import { Controller } from "@hotwired/stimulus"
import ace from 'ace-builds/src-min-noconflict/ace'
import 'ace-builds/src-min-noconflict/mode-liquid'


export default class extends Controller {
  static values = {
    mode: String
  }

  connect() {
    let editorDiv = this.editorDiv();
    this.element.parentNode.insertAdjacentElement('beforebegin', editorDiv);

    this.hideTextarea();
    this.instantiateEditor(editorDiv);
  }

  editorDiv() {
    let editorDiv = document.createElement('div');
    editorDiv.style.height = '200px';
    editorDiv.style.display = 'block';

    return editorDiv;
  }

  hideTextarea() {
    this.element.style.display = "none";
  }

  instantiateEditor(editorDiv) {
    let stimulus = this;

    stimulus.editor = ace.edit(editorDiv);
    stimulus.editor.session.setMode("ace/mode/" + (stimulus.modeValue || 'json'));
    stimulus.editor.session.setValue(stimulus.element.value);
    stimulus.editor.session.on('change', function(){
      stimulus.element.value = stimulus.editor.session.getValue();
    });
  }
}
