import { Controller } from "@hotwired/stimulus"

// input format: "custom text${inputValue}custom text"

export default class extends Controller {

  static targets = ['popup']

  static values = {
    content: String,
    format: String
  }

  copy(event) {
    event.preventDefault()
    this.copyTextToClipboard(this.contentValue)
  }

  copyInput(event) {
    event.preventDefault()
    let value = this.element.parentNode.querySelector('input')?.value || ''
    if(this.formatValue) {
      value = this.formatValue.replace('${inputValue}', value)
    }
    this.copyTextToClipboard(value)
  }

  showPopup() {
    this.popupTarget.classList.remove('hidden')
    this.popupTarget.classList.add('flex')
    setTimeout(() => {
      this.popupTarget.classList.add('hidden')
      this.popupTarget.classList.remove('flex')
    }, 500)
  }

  copyTextToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      if (this.hasPopupTarget) {
        this.showPopup()
      }
    }, function(err) {
      console.error('Async: Could not copy text: ', err)
    })
  }
}