import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['menu']

  entering = ['transition', 'ease-out', 'duration-100']
  opacity_100 = ['transform', 'opacity-100', 'scale-100']
  leaving = ['transition', 'ease-in', 'duration-75']
  opacity_0 = ['transform', 'opacity-0', 'scale-95']

  connect() {
    this.menuTarget.classList.add(...this.opacity_0, 'invisible')
  }

  toggle() {
    const menu = this.menuTarget
    const invisible = menu.classList.contains('invisible')
    console.log('menu', { invisible })
    if (invisible) {
      // transition transform
      menu.classList.remove('invisible')
      menu.style.transition = 'visibility 0s'
      setTimeout(() => {
        menu.style.transition = ''
        menu.classList.add(...this.entering)
        menu.classList.remove(...this.opacity_0)
        menu.classList.add(...this.entering, ...this.opacity_100)
        setTimeout(() => {
          menu.classList.remove(...this.entering)
        }, 100)
      }, 0)
    } else {
      menu.classList.remove(...this.opacity_100)
      menu.classList.add(...this.leaving, ...this.opacity_0)
      setTimeout(() => {
        menu.classList.remove(...this.leaving)
        menu.classList.add('invisible')
        menu.style.transition = 'visibility 0s'
      }, 75)
    }
  }
}