export const durationTime = (start, end) => {
  const MINUTE_IN_MS = 1000 * 60;
  const HOUR_IN_MINUTES = 60;
  const DAY_IN_HOURS = 24;

  const duration = Math.floor((end - start) / MINUTE_IN_MS);

  if (duration <= 1) {
    return 'just now';
  }

  if (duration < HOUR_IN_MINUTES) {
    return formatDuration(duration, 1, 'minute', 'minutes');
  }

  if (duration < HOUR_IN_MINUTES * DAY_IN_HOURS) {
    return formatDuration(duration, HOUR_IN_MINUTES, 'hour', 'hours');
  }

  return formatDuration(duration, HOUR_IN_MINUTES * DAY_IN_HOURS, 'day', 'days');

};

const formatDuration = (duration, unitSize, singularUnit, pluralUnit) => {
  const durationUnit = Math.floor(duration / unitSize);
  return `${durationUnit} ${singularOrPlural(durationUnit, singularUnit, pluralUnit)} later...`;
};

const singularOrPlural = (count, singular, plural) => {
  return count === 1 ? singular : plural;
};