import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['image']

  connect() {
    let image = this.imageTarget;

    let html = '<img src="' + image.src + '">'
    this.element.addEventListener('dragstart', (e) => {
      e.dataTransfer.setData('text/plain', html);
    })
  }
}
