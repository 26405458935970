import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['source', 'destination']

  connect() {
    const isActive = this.sourceTarget.classList.contains('active')
    this.destinationTarget.classList.toggle('hidden', !isActive)
    this.sourceTarget.addEventListener('click', () => this.toggleVisibility())
  }

  toggleVisibility() {
    // toggle btn style and destination visibility
    this.sourceTarget.classList.toggle('active')
    this.destinationTarget.classList.toggle('hidden')
  }
}