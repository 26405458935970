import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['wrapper', 'actions']

  show(e) {
    e.preventDefault();
    const height = document.querySelector('.dashboard-form-footer').clientHeight
    this.actionsTarget.style.bottom = `${height + 10}px`
    this.wrapperTarget.classList.remove('hidden')
  }

  hide() {
    this.wrapperTarget.classList.add('hidden')
  }

}
