import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "item"]
  
  static values = {
    termSelector: String
  }

  search() {
    const term = this.inputTarget.value.toLowerCase()
    const query = this.termSelectorValue
    this.itemTargets.forEach(item => {
      const string = item.querySelector(query).innerText.toLowerCase()
      const match = string.indexOf(term) >= 0
      item.style.setProperty('display', match ? '' : 'none')
    });
  }
}