import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"

export class AutoFormWithTargetController extends Controller {
  static targets = [
    'form'
  ]
  static values = {
    target: String,
    action: String
  }

  originalAction
  originalTarget

  initialize() {
    this.update = debounce(this.update, 300).bind(this)
  }

  connect() {
    this.originalAction = this.formTarget.getAttribute('action')
    this.originalTarget = this.formTarget.getAttribute('target') || '_self'

    // copy over the meta tag csrf token, because we're bypassing turbo
    let metaCsrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.formTarget.querySelector('input[name="authenticity_token"]').value = metaCsrf;
    this.update();
  }

  update() {
    this.submit(this.actionValue, this.targetValue)
  }

  submit(action, target) {
    this.formTarget.setAttribute('action', action)
    this.formTarget.setAttribute('target', target)
    this.formTarget.submit()
    this.formTarget.setAttribute('action', this.originalAction)
    this.formTarget.setAttribute('target', this.originalTarget)
  }
}
