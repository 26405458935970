import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['trigger', 'popup']

  connect() {
    if (this.hasTriggerTarget) {
      this.calcWidth()
      if ('ontouchstart' in window) {
        this.triggerTarget.addEventListener('click', this.present.bind(this))
        document.addEventListener('click', this.dismiss.bind(this))
      } else {
        this.triggerTarget.addEventListener('mouseenter', this.present.bind(this))
        this.triggerTarget.addEventListener('mouseleave', this.dismiss.bind(this))
      }
    }
  }

  dismiss() {
    this.popupTarget.classList.add('hidden')
    this.popupTarget.classList.remove('flex')
    this.popupTarget.classList.remove('align-right')
  }

  present(event) {
    event.preventDefault()
    event.stopPropagation()
    this.popupTarget.classList.remove('hidden')
    this.popupTarget.classList.add('flex')
    this.setPosition()
  }

  calcWidth() {
    if (!this.hasPopupTarget) return
    if (this.popupTarget.getBoundingClientRect().width > 300) {
      this.popupTarget.style.width = '300px'
      this.popupTarget.style.whiteSpace = 'normal'
    }
    this.setPosition()
    this.popupTarget.classList.add('hidden')
  }

  setPosition() {
    if (!this.hasPopupTarget) return
    const { left, width } = this.popupTarget.getBoundingClientRect()
    if((left + width) > window.innerWidth) {
      this.popupTarget.classList.add('align-right')
    }
  }
}