import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'voice',
    'button'
  ]

  connect() {
    this.buttonText = this.buttonTarget.innerHTML
  }

  sample(e) {
    e.preventDefault()

    console.log('testing')
    let voice_id = this.voiceTarget.value
    if (voice_id === '') return

    this.playSample(voice_id)
  }

  playSample(voice_id) {
    this.loading()
    const ctx = new AudioContext();
    let stimulus = this;

    fetch(`/ai/voices/${voice_id}`, {
      method: 'GET'
    })
      .then(data => data.arrayBuffer())
      .then(arrayBuffer => ctx.decodeAudioData(arrayBuffer))
      .then(decodedAudio => {
        const playSound = ctx.createBufferSource();
        playSound.buffer = decodedAudio;
        playSound.connect(ctx.destination);
        playSound.start(ctx.currentTime);

        this.loaded()
      })
  }

  loading() {
    this.buttonTarget.innerHTML = "Loading..."
  }

  loaded() {
    this.buttonTarget.innerHTML = this.buttonText
  }
}
