import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['label', 'modalTitle', 'deleteBtn', 'activeInput', 'activeIcon', 'activeValue', 'visibleHint', 'hiddenHint']
  static values = {
    onText: {
      type: String,
      default: 'Visible'
    },
    offText: {
      type: String ,
      default: 'Hidden'
    }
  }

  connect() {
    this.updateDOM(this.activeInputTarget.checked)
  }

  editItem() {
    this.modalTitleTarget.innerHTML = 'Edit'
    if (this.hasDeleteBtnTarget) {
      this.deleteBtnTarget.classList.remove('hidden')
    }
    this.dispatch('showModal')
  }

  toggleActive() {
    const active = !this.activeInputTarget.checked
    this.updateDOM(active)
  }


  updateDOM(active) {
    this.activeInputTarget.checked = active
    this.element.classList.toggle('hide', !active)
    this.activeIconTarget.classList.toggle('hide', !active)
    this.activeValueTarget.innerHTML = active ? this.onTextValue : this.offTextValue
    this.visibleHintTarget.classList.toggle('hidden', !active)
    this.hiddenHintTarget.classList.toggle('hidden', active)
  }

  updateLabel(e) {
    this.labelTarget.innerHTML = e.target.value
  }
}
