import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  connect() {
    let stimulus = this;

    this.element.addEventListener("dragover", (e) => {
      e.preventDefault();
    });

    this.element.addEventListener('drop', async (e) => {
      e.preventDefault();

      const files = e.dataTransfer.files;
      Array.from(files).forEach(file => stimulus.uploadFile(file, e))
    });
  }

  uploadFile(file, event) {
    let allowedExtensions = this.element.dataset.mimeTypes;
    if (allowedExtensions.indexOf(file.type) <= -1) {
      alert('Only images are allowed')
      return;
    }
    if (file.size > 3145728) {
      alert('Please limit files to 3MB or smaller')
      return;
    }

    let stimulus = this;
    const url = this.element.dataset.uploadUrl
    const upload = new DirectUpload(file, url)

    upload.create((error, blob) => {
      if (error) {
        alert('Failed to upload file "' + file.name + '"')
      } else {
        fetch('/email/images/' + blob.attachment_id)
          .then (response => response.text())
          .then(html => Turbo.renderStreamMessage(html));
      }
    })
  }
}
