import { Controller } from "@hotwired/stimulus"
import ace from 'ace-builds/src-min-noconflict/ace'
import 'ace-builds/src-min-noconflict/mode-liquid'
import { DirectUpload } from "@rails/activestorage"


export default class extends Controller {
  connect() {
    let editorDiv = this.editorDiv();
    this.element.parentNode.insertBefore(editorDiv, this.element.nextSibling);

    this.hideTextarea();
    this.instantiateEditor(editorDiv);
  }

  editorDiv() {
    let editorDiv = document.createElement('div');
    editorDiv.style.height = '100%';
    editorDiv.style.display = 'block';

    return editorDiv;
  }

  hideTextarea() {
    this.element.style.display = "none";
  }

  instantiateEditor(editorDiv) {
    let stimulus = this;
    let event = new Event("change");

    stimulus.editor = ace.edit(editorDiv);
    stimulus.editor.session.setMode("ace/mode/liquid");
    stimulus.editor.session.setValue(stimulus.element.value);
    stimulus.editor.session.on('change', function(){
      stimulus.element.value = stimulus.editor.session.getValue();
      stimulus.element.dispatchEvent(event);
    });
    this.enableFileUploads();
  }

  uploadFile(file, event) {
    if (!this.validateFile(file)) return;

    let stimulus = this;
    const url = stimulus.element.dataset.uploadUrl
    const upload = new DirectUpload(file, url)

    let loadingMessage = '<!-- Uploading "' + file.name + '..." -->';
    let pos = stimulus.editor.renderer.screenToTextCoordinates(event.pageX, event.pageY);
    stimulus.editor.session.insert(pos, loadingMessage)

    upload.create((error, blob) => {
      let range = stimulus.editor.find(loadingMessage,{
        wrap: true,
        caseSensitive: true,
        wholeWord: true,
        regExp: false,
        preventScroll: true
      })

      if (error) {
        stimulus.editor.session.replace(range, '<!-- Failed to upload file "' + file.name + '" -->')
      } else {
        fetch('/email/images/' + blob.attachment_id)
          .then (response => response.text())
          .then(html => Turbo.renderStreamMessage(html));
        stimulus.editor.session.replace(range, '<img src="' + blob.url + '">')
      }
    })
  }

  validateFile(file) {
    let allowedExtensions = this.element.dataset.mimeTypes;
    if (allowedExtensions.indexOf(file.type) <= -1) {
      alert('Only images are allowed')
      return false;
    }
    if (file.size > 3145728) {
      alert('Please limit files to 3MB or smaller')
      return false;
    }

    return true;
  }

  enableFileUploads() {
    let stimulus = this;

    stimulus.editor.container.addEventListener("dragover", (e) => {
      e.preventDefault();

      let pos = stimulus.editor.renderer.screenToTextCoordinates(e.pageX, e.pageY);
      stimulus.editor.moveCursorTo(pos.row, pos.column);
    });

    stimulus.editor.container.addEventListener('drop', async (e) => {
      e.preventDefault();

      const html = e.dataTransfer.getData('text/plain');
      let pos = stimulus.editor.renderer.screenToTextCoordinates(event.pageX, event.pageY);
      stimulus.editor.session.insert(pos, html)

      const files = e.dataTransfer.files;
      Array.from(files).forEach(file => stimulus.uploadFile(file, e))
    });
  }
}
