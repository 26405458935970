import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    autoDismiss: Boolean
  }

  connect() {
    let stimulus = this

    if (this.autoDismissValue) {
      setTimeout(function() {
        stimulus.element.open = false
      }, 5000)
    }
  }

  close(e) {
    e.stopPropagation()
    e.preventDefault()
    this.element.open = false
  }
}
