import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  toggle() {
    this.element.classList.toggle('open')
    let nextThead = this.element.nextElementSibling
    if (nextThead.tagName == 'THEAD') {
      nextThead.classList.toggle('hidden')
    }
    let nextTbody = nextThead.nextElementSibling
    if (nextTbody.tagName == 'TBODY') {
      nextTbody.classList.toggle('hidden')
    }
  }
}