import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['search', 'address1', 'address2', 'city', 'state', 'country', 'zip'];

  async connect() {
    let stimulus = this;
    const { Autocomplete } = await google.maps.importLibrary("places");

    const options = {
      componentRestrictions: { country: "us" },
      fields: ["address_components"],
      types: ["address"],
    };
    this.autocomplete = new Autocomplete(this.searchTarget, options);
    this.autocomplete.addListener("place_changed", () => { stimulus.fillAddress() });
  }

  fillAddress() {
    const place = this.autocomplete.getPlace();
    this.clearAddress();

    let results = this.formattedComponents(place.address_components);

    this.address1Target.value = `${results.street_number || ''} ${results.route}`;
    this.cityTarget.value = results.locality || '';
    this.stateTarget.value = results.administrative_area_level_1 || '';
    this.stateTarget.dispatchEvent(new Event('change'))
    if(this.hasCountryTarget) {
      this.countryTarget.value = results.country;
      this.countryTarget.dispatchEvent(new Event('change'))
    }
    this.zipTarget.value = results.postal_code || '';
  }

  formattedComponents(components) {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'short_name',
      postal_code: 'short_name'
    }
    let results = {};

    for (let i = 0; i < components.length; i++) {
      const addressType = components[i].types[0];
      if (componentForm[addressType]) {
        const val = components[i][componentForm[addressType]];
        results[addressType] = val;
      }
    }

    return results;
  }

  clearAddress() {
    this.address1Target.value = "";
    if(this.hasAddress2Target) {
      this.address2Target.value = "";
    }
    this.cityTarget.value = "";
    this.stateTarget.value = "";
    if(this.hasCountryTarget) {
      this.countryTarget.value = "";
    }
    this.zipTarget.value = "";
  }
}
