import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['img', 'option', 'edit', 'bundles']

  updateImg(option) {
    const img = option.getAttribute('data-img')
    if (img) {
      this.imgTarget.src = img
    }
  }

  showBundles(option) {
    const isBundle = option.getAttribute('data-is-bundle') == "true"
    let orderId = isBundle ? option.value : 0
    this.editTarget.classList.toggle('hidden',isBundle)
    this.bundlesTargets.forEach(item => {
      if(orderId && item.getAttribute('data-order-id') == orderId) {
        item.classList.toggle('hidden', false)
      } else {
        item.classList.toggle('hidden', true)
      }
    })
  }

  updateOption(element) {
    const selectedOption = element.selectedOptions || element.target.selectedOptions
    if (selectedOption && selectedOption[0]) {
      const option = selectedOption[0]
      this.updateImg(option)
      if(this.hasBundlesTarget) {
        this.showBundles(option)
      }
    }
  }

  connect() {
    if (this.optionTarget) {
      this.updateOption(this.optionTarget)
    }
  }
}