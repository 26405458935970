import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['display']

  connect() {
    const input = this.element.querySelector('input[type=color]')
    const update = () => {
      this.displayTarget.innerHTML = (input.value || input.placeholder).toUpperCase()
    }
    input.addEventListener('change', update)
    input.addEventListener('input', update)

    update()
  }
}