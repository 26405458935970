import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["switcher", "container"]

  static values = {
    layouts: String
  }

  layouts

  index

  connect() {
    this.layouts = this.layoutsValue.split(/,\s*/)
    this.index = this.layouts.length - 1
    this.toggle()
  }

  toggle(event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.index = (this.index + 1) % this.layouts.length
    const layout = this.layouts[this.index]
    this.element.style.setProperty('--layout', layout)
    this.element.setAttribute('data-layout', layout)
  }
}