import { Controller } from "@hotwired/stimulus"
import { durationTime } from '../helpers/utils'

export default class extends Controller {

  static targets = ["message"]

  lastTime = ''

  connect() {
    this.messageTargets.forEach((item) => {
      const currentTime = new Date(item.getAttribute('data-time'))
      if(this.lastTime && currentTime - this.lastTime > 5*60*1000) {
        const duration = document.createElement('div')
        duration.className = 'duration'
        duration.innerHTML = durationTime(this.lastTime, currentTime)
        item.parentNode.insertBefore(duration, item)
      }
      this.lastTime = currentTime
    })
  }
}