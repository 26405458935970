import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radio", "destination"]
  static values = {
    default: String
  }

  showDestination(value) {
    this.destinationTargets.forEach(destination => {
      const off = destination.dataset.value !== value
      destination.classList.toggle('hidden', off)
      const input = destination.querySelector('input')
      console.log(input);
      if (input) {
        input.disabled = off
      }
    })
  }

  connect() {
    this.showDestination(this.defaultValue)
    
    this.radioTargets.forEach(input => {
      if (input.value == this.defaultValue) {
        input.checked = true
      }
      input.addEventListener('change', (event) => {
        this.showDestination(event.target.value)
      })
    })
  }

}