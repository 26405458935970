import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  render(){
    this.element.querySelectorAll('tbody tr').forEach((row) => {
      row.hidden = row.dataset.filters
    })

    this.element.querySelectorAll('thead').forEach((thead) => {
      const tbody = thead.nextElementSibling
      const visibleRows = tbody.querySelectorAll('tr:not([hidden])')

      tbody.hidden = thead.hidden = visibleRows.length === 1
    })
  }
}
