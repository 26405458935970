import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radio", "destination"]

  toggleElement(element, isShow) {
    const input = element.querySelector('input')
    input.disabled = !isShow
    input.classList.toggle('disabled', !isShow)
    element.classList.toggle('hidden', !isShow)
    element.classList.toggle('disabled', !isShow)
  }

  change(element) {
    const selected = element.target.value
    this.destinationTargets.forEach(element => {
      const value = element.getAttribute('data-value')
      this.toggleElement(element, selected == value);
    })
  }

}
