import { Controller } from "@hotwired/stimulus"

const colorCodeForStatus = {
  shipped: '#FFC933', // yellow-default
  delivered: '#4DA350', // green-focus
  processing: '#3164F6', // blue-secondary
  out_for_delivery: '#FE7A01', // yellow-progress
  exception: '#DC4F19' // red-exception
}

const PinStyles = (google) => ({
  background: {
    anchor: new google.maps.Point(75, 48),
    url: '/assets/pin-sm-background@2x.png',
    scaledSize: new google.maps.Size(150, 52),
    labelOrigin: new google.maps.Point(48, 8)
  },
  merchantLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: '10px',
    fontWeight: '400',
    color: '#00092A',
    className: 'merchant-name'
  },
  timeLabel: {
    fontSize: '8px'
  },
  merchantIcon: {
    className: 'merchant-icon'
  }
})

export default class extends Controller {

  static values = { markIcon: String, strokeColor: String, strokeWeight: Number, pinStyle: String, pinBackgroundImage: String }

  handleSyntaxError(error) {
    if (Rollbar) {
      Rollbar.debug(error);
    } else {
      console.error(error);
    }
  }

  onDisplay(className) {
    return new Promise((resolve) => {
      let found
      let check = setInterval(() => {
        found = this.element.querySelector('.' + className)
        if (found) {
          clearInterval(check)
          resolve(found)
        }
      }, 100)
    })
  }

  newMap(center) {
    return new google.maps.Map(this.element, {
      center,
      mapTypeId: "roadmap",
      zoom: 11,
      minZoom: 5,
      disableDefaultUI: true,
      fullscreenControl: false,
      gestureHandling: 'cooperative',
      keyboardShortcuts: false
    })
  }

  initCoordinates() {
    if (!this.element.dataset.coordinates) return
    const coordinates = []
    let coordinates_raw
    try {
      coordinates_raw = JSON.parse(this.element.dataset.coordinates)
      if (!Array.isArray(coordinates_raw)) {
        throw new Error("dataset.coordinates should be an array")
      }
    } catch (e) {
      this.handleSyntaxError(e)
      return
    }
    coordinates_raw.forEach(c => {
      if (c && c[0]) {
        const latlng = new google.maps.LatLng(c[0], c[1])
        coordinates.push(latlng)
      }
    })
    return coordinates
  }

  buildMap() {
    const coordinates = this.initCoordinates() || []
    if (!coordinates || coordinates.length === 0) return
    const bounds = new google.maps.LatLngBounds()
    coordinates.forEach(c => {
      bounds.extend(c)
    })

    const map = this.newMap(bounds.getCenter())
    if (coordinates.length > 1) {
      map.fitBounds(bounds, 24)
    }
    map.panTo(coordinates[coordinates.length - 1])
    this.buildPaths(map, coordinates)
    this.buildCheckpoints(map, coordinates)
    this.buildMerchantNameAndTimestamp(map, coordinates)
    this.buildMerchantLogoAndStatus(map, coordinates)
  }

  buildPaths(map, coordinates) {
    new google.maps.Polyline({
      map,
      path: coordinates,
      geodesic: true,
      strokeColor: this.strokeColorValue || '#3164F6',
      strokeOpacity: 1.0,
      strokeWeight: this.strokeWeightValue || 4
    })
  }

  buildCheckpoints(map, coordinates) {
    const icon = {
      anchor: new google.maps.Point(19, 19),
      url: this.markIconValue,
      scaledSize: new google.maps.Size(38, 38)
    }
    let zIndex = 10
    coordinates.forEach((position) => {
      new google.maps.Marker({
        map,
        position,
        icon,
        zIndex: zIndex++
      })
    })
  }

  buildMerchantNameAndTimestamp(map, coordinates) {
    const style = PinStyles(google)
    new google.maps.Marker({
      map,
      position: coordinates[coordinates.length - 1],
      label: {
        ...style.merchantLabel,
        text: this.element.dataset.merchantName
      },
      icon: {
        ...style.background,
        url: this.pinBackgroundImageValue
      },
      zIndex: 10 + coordinates.length
    })
    this.onDisplay(style.merchantLabel.className).then(label => {
      label.setAttribute('data-value', this.element.dataset.checkpointTime)
    })
  }

  buildMerchantLogoAndStatus(map, coordinates) {
    const style = PinStyles(google)
    new google.maps.Marker({
      map,
      position: coordinates[coordinates.length - 1],
      label: {
        ...style.merchantIcon,
        text: ' '
      },
      zIndex: 11 + coordinates.length,
      icon: {
        ...style.background,
        url: "https://maps.gstatic.com/mapfiles/transparent.png",
      }
    })
    this.onDisplay(style.merchantIcon.className).then(icon => {
      icon.setAttribute('style', `
        --status-color: ${colorCodeForStatus[this.element.dataset.shipmentStatus]};
      `)
    })
  }

  async connect() {
    try {
      await deferred_map.promise;
      this.element.classList.add('pin-sm')
      this.buildMap();
    } catch (error) {
      if (error instanceof SyntaxError) {
        this.handleSyntaxError(error);
      } else {
        throw error;
      }
    }
  }

}