import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "selection", "output"]
  static values = {
    selection: String,
    selectedClasses: String
  }

  selectionValueChanged() {
    const selectedTabs = this.selectionValue.split(',')
    for (const tab of this.tabTargets) {
      const containingTabs = new Set(tab.dataset.tabsValue.split(','))
      const selected = selectedTabs.some(t => containingTabs.has(t))
      // console.log(containingTabs, selected)
      tab.classList.toggle('hidden', !selected)
    }
  }

  update(event) {
    if (!event.detail?.source) return
    if (event.detail.hide_others) {
      this.selectionValue = event.detail.source
    }
    const freeze = !!event.detail.freeze_selections
    if (typeof event.detail.output === 'object') {
      this.outputTargets.forEach(output => {
        const value = event.detail.output[output.dataset.outputKey]
        if (value) {
          output.innerHTML = value
          output.dataset.outputValue = value
        }
      })
    }
    this.selectionTargets.forEach(target => {
      target.classList.toggle('disabled', freeze)
    })
  }

  click(event) {
    event.stopPropagation()
    const [node] = this.selectionTargets.filter(node => node.contains(event.target))
    if (this.selectedClassesValue) {
      this.selectedClassesValue.split(' ').forEach((class_name) => {
        this.selectionTargets.forEach((selection) => {
          selection.classList.remove(class_name)
        })
      })
      this.selectedClassesValue.split(' ').forEach((class_name) => {
        node.classList.add(class_name)
      })
    }

    this.selectionValue = node.dataset.tabsValue
  }

  jumpTo(event) {
    event.stopPropagation()
    const selection = event.target.dataset.tabsValue
    this.selectionTargets.map(item => {
      if(item.dataset.tabsValue == selection) {
        item.dispatchEvent(new Event('click'))
      }
      return
    })
  }
}