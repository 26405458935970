import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'checkbox'
  ]

  confirm(e) {
    e.preventDefault()
    e.stopPropagation()

    if (this.checkboxTarget.checked) return
    this.dispatch('confirm')
  }

  proceed() {
    this.dispatch('proceed')
  }

  cancel() {
    // revert the change if canceled
    this.checkboxTarget.checked = !this.checkboxTarget.checked
    this.dispatch('cancel')
  }
}
