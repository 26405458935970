import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['source', 'destination']

  connect() {
    this.toggle();
  }

  toggle() {
    let enabled_element_class = this.relevantClass()

    this.destinationTargets.forEach(element => {
      if (element.className.indexOf(enabled_element_class) == -1) {
        this.toggleElement(element, 'off');
      } else {
        this.toggleElement(element, 'on');
      }
    })
  }

  relevantClass() {
    if (this.sourceTarget.type == 'checkbox') {
      if (this.sourceTarget.checked) {
        return this.sourceTarget.dataset.toggledElementClass
      } else {
        return null
      }
    } else {
      return this.relevantOption().dataset.toggledElementClass
    }
  }

  relevantOption() {
    let selected_option
    if (this.sourceTargets.length > 1) {
      selected_option = this.sourceTargets.filter((entry) => { return entry.checked })[0];
    } else {
      selected_option = this.sourceTarget.options[this.sourceTarget.selectedIndex];
    }

    return selected_option
  }

  toggleElement(element, state) {
    let displayStatus = state == 'on' ? 'block' : 'none';

    element.style.display = displayStatus;
    // check visibility because even if this element is not hidden, the parent may be, in which case its actual
    // visibility is false, and we should disable it
    let disabled = !element.checkVisibility()
    if (element.nodeName == 'FIELDSET') {
      element.disabled = disabled
      if (element.hasAttribute('required')) {
        element.required = !disabled
      }
    } else {
      let input = element.querySelectorAll('select, input, textarea');
      input.forEach((e) => {
        e.disabled = disabled
        if (e.hasAttribute('required')) {
          e.required = !disabled
        }
      })
    }
  }
}
