import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = ["selector", "button"]
  static values = { selection: String }

  connect() {
    $(this.selectorTarget).select2({
      width: '100%',
      dropdownCssClass: 'select-storefront',
      templateSelection: (data) => {
        return this.optionItem(data)
      },
      templateResult: (data) => {
        return this.optionItem(data, true)
      }
    })
    const _this = this
    $(this.selectorTarget).on('change', function (e) {
      const value = $(this).val()
      if (value)
        _this.selectionValue = value
      _this.buttonTarget.disabled = false
    });
    window.addEventListener("beforeunload", this.beforeUnload.bind(this));
  }

  disconnect() {
    this.beforeUnload();
  }

  beforeUnload() {
    $(this.selectorTarget).val('').trigger('change')
    $(this.selectorTarget).select2('destroy')
  }

  optionItem(data, isResult) {
    if (!$(data.element).val()) {
      return isResult ? null : $(`<span class="text-black/30 text-base">Select a storefront</span>`)
    }
    const icon = $(data.element).data('icon')
    const bg = $(data.element).data('bg')
    return $(`
      <span class="flex items-center">
        <span class="flex items-center justify-center mr-2 w-8 h-8 rounded-full overflow-hidden"
          style="background-color: ${bg};"
        >
          <img class="max-w-full max-h-full" src="${icon}" />
        </span>
        ${data.text}
      </span>
    `)
  }

  go() {
    if (this.selectionValue) {
      window.location.href = this.selectionValue
    }
  }
}
