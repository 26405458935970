import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"]

  draggedNode = null
  inputNode = null
  dragging = false
  outside = false

  mousedown() {
    this.toggleDraggable(true)
  }

  mouseup() {
    if (!this.dragging) {
      this.toggleDraggable(false)
    }
  }

  dragleaveContainer(e) {
    this.setOutside(e, true)
  }

  dragenterContainer(e) {
    this.setOutside(e, false)
  }

  setOutside(e, flag) {
    if (this.dragging && (e.relatedTarget === null || !this.element.contains(e.relatedTarget))) {
      this.outside = flag
    }
  }

  dragstart(e) {
    if (e.target.classList?.contains('list-item')) {
      this.dragging = true
      this.draggedNode = e.target
      const nextSibling = e.target.nextElementSibling
      if (nextSibling.tagName.toLowerCase() == 'input') {
        this.inputNode = nextSibling
      }
    }
  }
  dragover = e => {
    e.preventDefault()
  }
  dragend(e) {
    if (this.dragging) {
      this.dragging = false
      if (this.outside) {
        this.toggleDraggable(false)
        this.outside = false
      }
    }
  }
  drop(e) {
    if (!this.dragging) return
    const item = e.target.classList.contains('list-item') ? e.target : e.target.closest('.list-item')
    if (this.draggedNode !== null && this.draggedNode !== item) {
      const dragBox = this.element
      dragBox.insertBefore(this.draggedNode, item);
      this.inputNode && dragBox.insertBefore(this.inputNode, item);
      this.draggedNode = null
      this.inputNode = null
      this.reOrder()
    }
    this.toggleDraggable(false)
  }

  toggleDraggable(isDraggable) {
    this.itemTargets.forEach(item => item.draggable = isDraggable)
  }

  reOrder() {
    this.itemTargets.forEach((item, index) => {
      item.querySelector('[name*="[ordering]"]').value = index
    })
  }

}
