import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selection"]
  static values = { selection: [String] }

  connect() {
    for (const selection of this.selectionTargets) {
      const input = selection.querySelector('input[type=checkbox]')
      selection.classList.toggle('selected', input.checked)
    }
  }

  click(event) {
    event.stopPropagation()
    const [selection] = this.selectionTargets.filter(node => node.contains(event.target))
    selection.classList.toggle('selected')
    const input = selection.querySelector('input[type=checkbox]')
    input.checked = selection.classList.contains('selected')
  }
}