import { Chart, registerables } from "chart.js"
Chart.register(...registerables);
import { Controller } from "@hotwired/stimulus"
import deepmerge from "deepmerge"

export default class extends Controller {
  static values = {
    config: Object,
    format: String
  }

  connect() {
    let ctx = this.element.getContext('2d')
    console.log(this.config())
    new Chart(ctx, this.config())
  }

  config() {
    let originalConfig = this.configValue
    let formatConfig = {}

    if (this.formatValue === 'currency') {
      formatConfig = this.currencyConfig()
    }

    return deepmerge(originalConfig, formatConfig)
  }

  currencyConfig() {
    return {
      options: {
        scales: {
          y: {
            ticks: {
              callback: function(value) {
                return "$" + value
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                return "$" + context.parsed.y
              }
            }
          }
        }
      }
    }
  }
}
