import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['content', 'style']

  static values = { queryTotal: String, queryCompleted: String }

  connect() {
    const total = this.element.querySelectorAll(this.queryTotalValue).length
    const completed = this.element.querySelectorAll(this.queryCompletedValue).length
    const progress = `${completed}/${total}`
    for (const content of this.contentTargets) {
      content.innerHTML = progress
    }
    for (const style of this.styleTargets) {
      style.style.setProperty('--progress', progress)
    }
  }
}