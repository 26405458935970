import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['popup']

  static values = { sheetClasses: String, clone: Boolean }

  originPopupTargetParentNode

  originPopupTarget

  connect() {
    this.originPopupTarget = this.popupTarget
    this.popupTargetOriginParentNode = this.originPopupTarget.parentNode
    if (this.responsiveFullValue) {
      this.appendCloseBar()
    }
    this.originPopupTarget.classList.add('hidden')
  }

  disconnect() {
    this.originPopupTarget.classList.add('hidden')
    if (this.cloneValue === false) {
      this.popupTargetOriginParentNode.appendChild(this.originPopupTarget)
    }
    if (this.background) {
      this.background.remove()
    }
  }

  appendPopupTarget(parent) {
    if (this.cloneValue === false) {
      parent.appendChild(this.originPopupTarget)
      this.originPopupTarget.classList.remove('hidden')
    } else {
      const clone = this.popupTarget.cloneNode(true)
      clone.classList.remove('hidden')
      parent.appendChild(clone)
    }
  }

  buildPopupBackground() {
    const background = document.createElement('div')
    background.classList.add('fixed', 'inset-0', 'z-50', 'flex')
    document.body.appendChild(background)
    const shade = document.createElement('div')
    shade.classList.add('opacity-40', 'bg-black', 'fixed', 'inset-0')
    background.appendChild(shade)
    background.addEventListener('click', (event) => {
      if (this.sheet) {
        let isLoading = false
        this.sheet.childNodes.forEach(node => {
          if (node.classList.contains('loading')) {
            isLoading = true
          }
        })
        if (!isLoading) {
          this.close(event)
        }
      }
    })
    return background
  }

  removeLoading(hideOthers) {
    this.sheet.childNodes.forEach(node => {
      if (node.classList.contains('loading')) {
        node.remove()
      } else {
        node.classList.toggle('hidden', hideOthers)
      }
    })
  }

  startLoading() {
    if (this.sheet) {
      const div = document.createElement('div')
      div.classList.add('text-center', 'my-20', 'mx-auto', 'loading')
      const lds = document.createElement('div')
      lds.classList.add('lds-ring')
      div.appendChild(lds)
      for (let i = 0; i < 4; i++) {
        lds.appendChild(document.createElement('div'))
      }
      const text = document.createElement('div')
      text.classList.add('text-gray-88', 'mt-6')
      text.innerHTML = 'Loading...'
      div.appendChild(text)
      this.removeLoading(true)
      this.sheet.appendChild(div)
    }
  }

  stopLoading() {
    if (this.sheet) {
      this.removeLoading(false)
    }
  }

  close(event) {
    if (this.background) {
      event.preventDefault()
      event.stopPropagation()
      this.background.remove()
    }
  }

  popup(event) {
    event.preventDefault()
    event.stopPropagation()
    window.dispatchEvent(new CustomEvent('dismiss-popups'))
    this.background = this.buildPopupBackground()
    const sheetContainer = document.createElement('div')
    sheetContainer.classList.add('flex-grow', 'flex')
    sheetContainer.addEventListener('click', (event) => {
      event.stopPropagation()
    })
    this.background.appendChild(sheetContainer)
    const sheet = document.createElement('div')
    sheet.classList.add('m-auto', 'sheet', 'z-10', 'text-center', 'max-h-screen', 'overflow-y-auto')
    if (this.sheetClassesValue) {
      sheet.classList.add(...this.sheetClassesValue.split(' '))
    }
    this.appendPopupTarget(sheet)
    sheetContainer.appendChild(sheet)
    sheet.querySelectorAll('.close-button').forEach(closeButton => {
      closeButton.addEventListener('click', this.close.bind(this))
    })
    this.sheet = sheet
  }
}