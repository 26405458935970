import { Controller } from "@hotwired/stimulus"
const MenuKey = "DESKTOP_MENU";

export default class extends Controller {
  static targets = [
    // mobile
    "sidebar", "overlay", "menu", "button",
    // desktop
    "desktopOverlay", "desktopMenu",
  ]

  loading = false

  initialize() {
    // Set desktop menu default state
    const isMenuClosed = JSON.parse(window.sessionStorage.getItem(MenuKey)) || false;
    this.desktopOverlayTarget.classList.toggle('close', isMenuClosed)
    this.desktopMenuTarget.classList.toggle('close', isMenuClosed)
  }

  open(e) {
    this.toggle(e)
  }

  close(e) {
    this.toggle(e)
  }

  toggle(e) {
    e.stopPropagation()
    e.preventDefault()
    let open = !this.menuTarget.open
    this.menuTarget.open = open
    this.overlayTarget.open = open
    this.sidebarTarget.open = open
    this.buttonTarget.open = open
    this.buttonTarget.classList.toggle('open', open)
  }

  // desktop
  desktopOverlayToggle(e) {
    e.stopPropagation()
    e.preventDefault()
    let close = !this.desktopOverlayTarget.classList.contains("close")
    this.desktopOverlayTarget.classList.toggle('close', close)
    this.desktopMenuTarget.classList.toggle('close', close)
    window.sessionStorage.setItem(MenuKey, close)
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 300)
  }

  overMenu(e) {
    e.stopPropagation()
    e.preventDefault()
    let isOverlayclosed = this.desktopOverlayTarget.classList.contains("close")
    let isMenuclosed = this.desktopMenuTarget.classList.contains("close")
    if (isOverlayclosed && isMenuclosed && !this.loading) {
      this.desktopMenuTarget.classList.remove('close')
    }
  }

  outMenu(e) {
    e.stopPropagation()
    e.preventDefault()
    let isOverlayclosed = this.desktopOverlayTarget.classList.contains("close")
    let isMenuclosed = this.desktopMenuTarget.classList.contains("close")
    if (isOverlayclosed && !isMenuclosed && !this.loading) {
      this.desktopMenuTarget.classList.add('close')
    }
  }

}
